import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "button"]

  addOne(event) {
    event.preventDefault()
    const property = this.templateTarget.content.cloneNode(true)
    this.element.insertBefore(property, this.buttonTarget)
  }

  removeOne(event) {
    event.currentTarget.closest(".property").remove()
    const sendRequestController = this.application.getControllerForElementAndIdentifier(document.querySelector("main"), "explorer-send-request")
    sendRequestController.updatePreview()
  }
}
